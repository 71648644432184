/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "2928C (Modlibórz – Kłóbka – Chodecz) na odcinku od skrzyżowania z drogą krajową nr 91 (w Modliborzu) do połączenia z zakresem wykonanym w etapie I (w Chodczu). Odcinek objęty rozbudową, o długości 10,267 km, przebiega przez miejscowości: Modlibórz, Kłóbka, Rzeżewo, Sobiczewy, Chodecz."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych wykonano następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbudowa jezdni o nawierzchni z mieszanki mineralno-asfaltowej o szerokości 6,0m;"), "\n", React.createElement(_components.li, null, "budowa poboczy o nawierzchni z kruszywa łamanego;"), "\n", React.createElement(_components.li, null, "przebudowa wlotów na skrzyżowaniach z drogą powiatową i drogami gminnymi,"), "\n", React.createElement(_components.li, null, "budowa ciągu pieszo – rowerowego o nawierzchni z mieszanki mineralno-asfaltowej (na odcinku od skrzyżowania z drogą krajową nr 91 (w Modliborzu) do skrzyżowania z drogą gminną prowadzącą do jeziora Rodzikowo (w Kłóbce) oraz od skrzyżowania z drogą gminną w rejonie remizy/świetlicy w Sobiczewach do Chodcza) – długość 5 560m;"), "\n", React.createElement(_components.li, null, "budowa i przebudowa chodnika o nawierzchni z kostki betonowej – długość 1 140m;"), "\n", React.createElement(_components.li, null, "budowa i przebudowa zjazdów indywidualnych o nawierzchni z kostki betonowej oraz z mieszanki mineralno-asfaltowej;"), "\n", React.createElement(_components.li, null, "regulacja i odmulenie rowów przydrożnych."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
